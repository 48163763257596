import { Injectable } from '@angular/core';
import { AppConstant } from './app.constant';
import { CoreHttpService } from './core-http.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class AppService {

  session: OT.Session;
  token: string;
  coreUrl: string;

  constructor(private coreHttpService: CoreHttpService) {
    this.coreUrl = AppConstant.apiUrl;
  }

  getOT() {
    return OT;
  }

  get role() {
    let x = JSON.parse(localStorage.getItem('UserDataStoringLocally')).role;
    return x;
  }

  /**
   * Get Credential
   *
   * @param {*} eventId
   * @param {*} userTypeId
   * @returns
   * @memberof AppService
   */
  getCredential(eventId, userTypeId) {
    var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
    return this.coreHttpService.httpGetRequest(this.coreUrl + "Video/GenerateToken?eventId=" + eventId + '&user=' + userTypeId, reqHeaders);
  }

  getBroadCast(broadcastSessionId: any, eventId: any, streams?: any, rtmp?: any) {
    var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
    return this.coreHttpService.httpGetRequest(this.coreUrl + "Video/GetBroadcastDetail?sessionId=" + broadcastSessionId + '&eventId=' + eventId, reqHeaders);
  }


  // getSession(eventId, userTypeId) {
  //     var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
  //     return this.coreHttpService.httpGetRequest(this.coreUrl + "Video/GenerateSession?eventId=" + eventId + '&user=' + userTypeId, reqHeaders);
  // }

  /**
   *Generate Session
   *
   * @param {*} eventId
   * @param {*} userTypeId
   * @returns
   * @memberof AppService
   */
  getSession(eventId, userTypeId) {
    var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
    return this.coreHttpService.httpGetRequest(this.coreUrl + "Video/GenerateSession?eventId=" + eventId, reqHeaders);
  }

  /**
   *Get User By ID
   *
   * @param {*} userId
   * @returns
   * @memberof AppService
   */
  getUserById(userId) {
    var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
    return this.coreHttpService.httpGetRequest(this.coreUrl + "User/GetUserById?id=" + userId, reqHeaders);
  }
  /**
   *Get Event By ID
   *
   * @param {*} userId
   * @param {*} isRelatedEvent
   * @returns
   * @memberof AppService
   */
  getEventById(userId, isRelatedEvent) {
    var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
    return this.coreHttpService.httpGetRequest(this.coreUrl + "Event/GetById?id=" + userId + '&isRelatedEvent=' + isRelatedEvent, reqHeaders);
  }
  /**
   *Validate User
   *
   * @memberof AppService
   */
  checkUser = (filter) => {
    var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
    return this.coreHttpService.httpPostRequest(this.coreUrl + "Video/CheckUserAndAuthenticate", filter, reqHeaders);
  }
  /**
   *Disconnect User from conference
   *
   * @memberof AppService
   */
  forceDisconnect = (eventId, userId) => {
    var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
    return this.coreHttpService.httpGetRequest(this.coreUrl + "Video/DisconnectUser?eventId=" + eventId + '&userId=' + userId, reqHeaders);
  }
  /**
   *Set Connection Session from here
   *
   * @memberof AppService
   */
  setConnenctionSession = (connection) => {
    var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
    return this.coreHttpService.httpGetRequest(this.coreUrl + "Video/SaveConectionId?eventId=" + connection.eventId + '&userId=' + connection.userId + '&connectionId=' + connection.connectionId, reqHeaders);
  }
  /**
   *Stop Broadcast
   *
   * @memberof AppService
   */
  stopBroadcast = (eventId) => {
    var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
    return this.coreHttpService.httpGetRequest(this.coreUrl + "Video/StopBroadCast?eventId=" + eventId, reqHeaders);
  }
  /**
   *Used to close discussion from here
   *
   * @memberof AppService
   */
  stopdiscussion = (eventId) => {
    var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
    return this.coreHttpService.httpGetRequest(this.coreUrl + "Video/CloseDiscussion?eventId=" + eventId, reqHeaders);
  }

  GetBroadcastStartTime = (eventId) => {
    var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
    return this.coreHttpService.httpGetRequest(this.coreUrl + "Video/GetBroadcastStartTime?eventId=" + eventId, reqHeaders);
  }

  GetModeratorConnectionId = (eventId) => {
    var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
    return this.coreHttpService.httpGetRequest(this.coreUrl + "Video/GetModeratorConnectionId?eventId=" + eventId, reqHeaders);
  }

  AudianceDiscussionSave = (audianceDetail) => {
    var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
    return this.coreHttpService.httpPostRequest(this.coreUrl + "Event/AudianceDiscussionSaveDetai", audianceDetail, reqHeaders);
  }

  getAudianceById = (audianceId) => {
    var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
    return this.coreHttpService.httpGetRequest(this.coreUrl + "Audience/GetAudianceById?audianceId=" + audianceId, reqHeaders);
  }


  //Audiance Start Discussion
  AddStartTimeWhileVideoRunning = (audianceDetail) => {
    var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
    return this.coreHttpService.httpPostRequest(this.coreUrl + "Event/AddStartTimeWhileVideoRunning", audianceDetail,
      reqHeaders);
  }

  //Audiance End Discussion
  AddEndTimeWhileVideoRunning = (audianceDetail) => {
    var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
    return this.coreHttpService.httpPostRequest(this.coreUrl + "Event/AddEndTimeWhileVideoRunning",
      audianceDetail,
      reqHeaders);
  }

  //Audiance Add questions
  AddQuestionsWhileVideoRunning = (audianceQuestionModel) => {
    var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
    return this.coreHttpService.httpPostRequest(this.coreUrl + "Event/AddQuestionsWhileVideoRunning",
      audianceQuestionModel,
      reqHeaders);
  }

  getQuestionList = (eventId) => {
    var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
    return this.coreHttpService.httpGetRequest(this.coreUrl + "Event/GetEventQuestions?eventid="+ eventId, reqHeaders)
  }

}
