import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { map, catchError } from 'rxjs/operators';

@Injectable()
export class CoreHttpService {

    constructor(
        private http: HttpClient
    ) {
    }
    httpGetRequest<TResponse>(url: string, header?: HttpHeaders): Observable<TResponse> {
        return this.http.get(url, { headers: header })
            .pipe(
                map(res => {
                    return <TResponse>res
                }),
                catchError(this.customErrorHandlors));

    }

    httpPostRequest<TRequest, TResponse>(url: string, user: TRequest, header?: any): Observable<TResponse> {
        return this.http.post(url, user, { headers: header })
            .pipe(
                map(res => {
                    return <TResponse>res
                }),
                catchError(this.customErrorHandlors))
    }

    // httpDeleteRequest<TRequest, TResponse>(url: string, id?: TRequest): Observable<TResponse> {
    //     // this.loderService.display(true);
    //     return this.http.delete(url, id)
    //         .map(res => {
    //             // this.loderService.display(false);
    //             return <TResponse>res
    //         }).catch(this.customErrorHandlors);
    // }

    customErrorHandlors = (error: HttpErrorResponse | any) => {
        
        if (!!error.error) {
        }
        if (!!error.error.error && error.error.error !== "") {
            // Swal(error.error.error, error.error.error_description, 'error')
        }
        else if (error.status === 401) {
            // Swal("Unauthorized", error.error.message, 'error')
        }


        // let errors = (!this.coreService.isNullOrUndefined(error.error.exceptionMessage)) ? error.error.exceptionMessage : error.error.message;
        // let error1: string = '';
        // let error2: string = '';
        // let errorStatus = (error.statusText === "Unknown Error") ? "errors" : error.statusText;

        // this.loderService.display(false);

        // if (error.status === 0) {
        //     errors = "You are offline!";
        // }
        // else if (!this.coreService.isNullOrUndefined(error.error.innerException)
        //     && error.error.innerException.exceptionMessage !== '') {
        //     error1 = error.error.innerException.exceptionMessage;

        //     if (!this.coreService.isNullOrUndefined(error.error.innerException.innerException) && error.error.innerException.innerException.exceptionMessage !== '') {
        //         error2 = error.error.innerException.innerException.exceptionMessage;
        //     }
        // }
        // this.coreService.exceptionDialog(errorStatus, errors, error1, error2);
        return Observable.throw(error.message);
    }
}