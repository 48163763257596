export class UserFilterModel {
    eventId?: number;
    userId?: number;
    accessCode?: string;
    emailAddress?: string;
}


export class AudianceDataToSaveModel {
    audianceId?: number;
    eventId?: number;
    questionList?: Array<string> | any;
    audianceStartTime?: Date;
    browserTimezone?: string;
    audianceBrowserInfo?: string;
}

export class AudianceQuestionModel {
    id?: number; // Id (Primary key)
    eventId?: number; // EventId
    audianceId?: number;
    audianceName?: string;
    question?: string;
    createdOn?: string;
}

export class WhoDetails {
    layoutId?: number;
    role?: string;
    boxid?: number | string;
    eventId?: number | string;
    userId?: number | string;;
    // this.isAdmin = this.role == 'admin' ? true : false;

}