import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { CoreHttpService } from './core-http.service';
import { AppService } from './app.service';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { RouterModule } from '@angular/router';
import {MatSliderModule} from '@angular/material/slider';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SliderModule} from 'primeng/slider';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import {NgbdTooltipBasicModule} from './app/tooltip-basic.module';

const routes: Routes = [
  {
    path: '',
    component: AppComponent
  }
];

@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressBarModule,
    MatSliderModule,
    BrowserAnimationsModule,
    SliderModule,
    NgbModule

  ],
  providers: [CoreHttpService, AppService],
  bootstrap: [AppComponent]
})
export class AppModule { }
