export class AppConstant {

    // API URLS
    // public static url = "http://localhost:4000/";
    // public static url = "http://localhost:4200/";
    // public static apiUrl = "http://localhost:3472/api/";
    // public static loginTokenUrl = "http://localhost:3472/";

    // public static apiUrl = "http://techext-002-site54.atempurl.com/api/";
    // public static loginTokenUrl = "http://techext-002-site54.atempurl.com/";

    // https://localhost/ConferenceAPI/API/User/GetAllUsers


    // public static apiUrl = "https://localhost/ConferenceAPI/API/";
    // public static loginTokenUrl = "https://localhost/ConferenceAPI/";


    // Web conference live URL
    // public static url = "http://techext-002-site33.atempurl.com/";
    // public static apiUrl = "http://techext-002-site29.atempurl.com/api/";
    // public static loginTokenUrl = "http://techext-002-site29.atempurl.com/";

    // public static url = "http://www.discussionbox.com/";
    // public static apiUrl = "http://3.14.82.237/Api/api/";
    // public static loginTokenUrl = "http://3.14.82.237/Api/";


    // LIVE DISCUSSION URLS
    public static url = "http://techext-001-site1.itempurl.com/";
    public static apiUrl = "https://techext-001-site2.atempurl.com/api/";
    public static loginTokenUrl = "https://techext-001-site2.atempurl.com/Api/";

}

export default {
    // Set this to the base URL of your sample server, such as 'https://your-app-name.herokuapp.com'.
    // Do not include the trailing slash. See the README for more information:
    SAMPLE_SERVER_BASE_URL: 'http://YOUR-SERVER-URL',
    // OR, if you have not set up a web server that runs the learning-opentok-php code,
    // set these values to OpenTok API key, a valid session ID, and a token for the session.
    // For test purposes, you can obtain these from https://tokbox.com/account.
    API_KEY: '46392302',
    SESSION_ID: '2_MX40NjM5MjMwMn5-MTU2NDYxNjkzODMyNH5FTE1sUEFkTWhGZXdkTzdzeDFLajFQNzd-fg',
    TOKEN: 'T1==cGFydG5lcl9pZD00NjM5MjMwMiZzaWc9NzNiMTIyODgwYWU5ZWFjZmFiNTdjYTgwZjk1NjM0YWExN2EyYzA5YTpzZXNzaW9uX2lkPTJfTVg0ME5qTTVNak13TW41LU1UVTJORFl4Tmprek9ETXlOSDVGVEUxc1VFRmtUV2hHWlhka1R6ZHplREZMYWpGUU56ZC1mZyZjcmVhdGVfdGltZT0xNTY0NjE3MDAwJm5vbmNlPTAuNzQzMDY1MTgyNDMxMzE4NCZyb2xlPW1vZGVyYXRvciZleHBpcmVfdGltZT0xNTY0NzAzMzk4JmluaXRpYWxfbGF5b3V0X2NsYXNzX2xpc3Q9',
    TOKEN_PUBLISHER: 'T1==cGFydG5lcl9pZD00NjM5MjMwMiZzaWc9NjA3M2M5ODJkNGFhM2MxNDVlNTU4N2M1ZTE0NjI0Nzc2OGZhOTM5NDpzZXNzaW9uX2lkPTJfTVg0ME5qTTVNak13TW41LU1UVTJORFl4Tmprek9ETXlOSDVGVEUxc1VFRmtUV2hHWlhka1R6ZHplREZMYWpGUU56ZC1mZyZjcmVhdGVfdGltZT0xNTY0NjE4MTEyJm5vbmNlPTAuNjk0MjQ5MzkzMjg1OTEyNCZyb2xlPXB1Ymxpc2hlciZleHBpcmVfdGltZT0xNTY0NzA0NTEwJmluaXRpYWxfbGF5b3V0X2NsYXNzX2xpc3Q9'
};
