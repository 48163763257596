import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  ChangeDetectorRef,
  AfterViewInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT, JsonPipe } from '@angular/common';
import * as OT from '@opentok/client';
import * as _ from 'lodash';
import * as R from 'ramda';
import * as $ from 'jquery';
// declare var $: any;

let canvas;

import { AppService } from './app.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserFilterModel, AudianceDataToSaveModel, AudianceQuestionModel, WhoDetails } from './app.class';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild("video1", { static: true }) video: ElementRef; //canvas
  @ViewChild("canvas1", { static: true }) canvas: ElementRef;

  sessionID = "";
  session: OT.Session;
  streams: Array<OT.Stream> = [];
  changeDetectorRef: ChangeDetectorRef;
  roomCreated = false;
  publisher: OT.Publisher;
  publishing: Boolean;
  role: string;
  boxid: number;
  layoutId: number;
  eventId: number;
  userId: number;
  userData: any;
  event: any;
  isAdmin = false;
  IsQueNull = true;
  isUserAuthenticated?: boolean = false;
  loginForm: FormGroup;
  emailAddress?: string = "";
  accessCode?: string = "";
  subscriptionData: any;
  whoIsSpeaking: any;
  totaltimediferrence: any;
  totaltimeelapsed: any;
  elapsedseconds: any;
  // bufferValue:any;
  color = 'primary';
  mode = 'determinate';
  value = 50;
  bufferValue = 75;
  val = 100;

  IDD: any;
  CONNECT: any;

  IsVisible = 'false';
  IspersonVisible: any;
  firstUser: any;
  secondUser: any;
  thirdUser: any;
  forthUser: any;
  fifthUser: any;
  sixUser: any;
  seventhUser: any;
  eigththUser: any;
  ningthUser: any;

  firstInfo: any;
  secondInfo: any;
  thirdInfo: any;
  forthInfo: any;
  fifthInfo: any;
  sixthInfo: any;
  seventhInfo: any;
  eighthInfo: any;
  ninthInfo: any;
  allInfo: any;

  firstSpeaker: any;
  secondSpeaker: any;
  thirdSpeaker: any;
  forthSpeaker: any;
  fifthSpeaker: any;
  sixthSpeaker: any;
  allSpeaker: any;

  IsStart = true;
  isBroadcastStarted;
  dialogId;

  adminDialogVar?: any;
  totalViwer: any = 0;
  count = "0";

  isShowSMModel?: boolean = false;

  broadCastStartdate?: Date | any;

  myDate: any;
  questionList: Array<string> = [];
  question?: string = "";

  isModerator: boolean = false;

  receivedQuestion: Array<string> = [];
  audianceId?: number;
  audianceStartTime?: Date = null;
  recordId: any;
  isShow = false;

  audianceDetail?: any = {};


  isonLine: boolean = false;
  submitted: boolean = false;
  isvideoOff: boolean = true;
  isaudioOff: boolean = true;
  alreadyAskedQuestionList: any[] = [];
  userIDFromRoute: any;
  isShow1 = false;
  isShow2 = false;
  isShow3 = false;
  isShow4 = false;
  isShow5 = false;
  isShow6 = false;
  isShow7 = false;
  isShow8 = false;
  isShow9 = false;
  isaudioOnOrOff1: boolean = false;
  isaudioOnOrOff2: boolean = false;
  isaudioOnOrOff3: boolean = false;
  isaudioOnOrOff4: boolean = false;
  isaudioOnOrOff5: boolean = false;
  isaudioOnOrOff6: boolean = false;
  isaudioOnOrOff7: boolean = false;
  isSpeakerRemoved: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
    @Inject(DOCUMENT) private doc: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    private formBuilder: FormBuilder,
  ) {

    window.addEventListener('offline', (event) => {
      alert("You lost connection.");
    });
    window.addEventListener('online', (event) => {
      alert("you are online.");
      this.disconnectuserWhileInternateDown();
    });


    setInterval(() => {
      // This function is used for calculate time elapsed at every side
      //replaced function() by ()=>
      if (!!this.broadCastStartdate) {
        // console.log("In Start broadcast date if date is there" +this.broadCastStartdate)
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var broadCastdate = new Date(this.broadCastStartdate).toLocaleString("en-US", { timeZone: tz });
        // var a = new Date(this.broadCastStartdate).toUTCString("en-US", { timeZone: tz });
        // let broadCastdate = new Date(this.broadCastStartdate);
        let bh = new Date(broadCastdate).getHours();
        let bm = new Date(broadCastdate).getMinutes();
        let bs = new Date(broadCastdate).getSeconds();
        var d1 = new Date();
        var d2 = new Date(d1);
        d2.setHours(d1.getHours() - bh);
        d2.setMinutes(d1.getMinutes() - bm);
        d2.setSeconds(d1.getSeconds() - bs);
        this.myDate = new Date(d2);
      } else {
        this.myDate = undefined;
      }
      this.SetTimeElspsed();
    }, 1000);


    // this.isBroadcastStarted = JSON.parse(localStorage.getItem("isBroadcastStarted"));

    this.publishing = false;
    this.IsVisible = 'all';


    this.onMouseOver('data', 'all');
    setTimeout(() => {
      this.onMouseOver('data', 'none');

    }, 180000);


    this.route
      .queryParams
      .subscribe(params => {
        // To get Vaues from Routing parameter
        this.layoutId = params.layoutId;
        this.role = params.role;
        this.boxid = params.bid;
        this.eventId = params.eventId;
        this.userId = params.userId;
        this.isAdmin = this.role == 'admin' ? true : false;

        // Storing data in local storage
        var dataToStoreAslocally = new WhoDetails();
        dataToStoreAslocally.boxid = this.boxid;
        dataToStoreAslocally.role = this.role;
        dataToStoreAslocally.eventId = this.eventId;
        dataToStoreAslocally.layoutId = this.layoutId;
        dataToStoreAslocally.userId = this.userId;

        localStorage.setItem("UserDataStoringLocally", JSON.stringify(dataToStoreAslocally));

        if (this.role == "viwer") {
          // passing audience id
          this.audianceId = params.auId;
          this.isonLine = true;
          this.appService.getAudianceById(this.audianceId)
            .subscribe(data => {
              if (!!data) {
                this.audianceDetail = data;
                this.audianceDetail.fullName = this.audianceDetail.firstName + " " + this.audianceDetail.lastName;
              }
            });

          // To store Data In Start Time in database - AudienceDiscussionMapping
          let startTimeobj = new AudianceDataToSaveModel();
          startTimeobj = {
            audianceId: this.audianceId,
            audianceStartTime: new Date(),
            eventId: this.eventId,
            audianceBrowserInfo: this.getBrowserinfo()
          };

          this.appService.AddStartTimeWhileVideoRunning(startTimeobj)
            .subscribe((data: any) => {
            });

        }

        // not in used
        this.isModerator = (this.layoutId == this.boxid);

        if (this.role !== undefined) {
          if (localStorage.getItem("isAuth") == "true") {
            this.isUserAuthenticated = true;
            this.doVideoStuff();
          } else {
            if (!!this.role && this.role.toLowerCase() == "moderator" || this.role.toLowerCase() == "speaker") {
              this.isUserAuthenticated = false
            } else {
              this.isUserAuthenticated = true;
              localStorage.setItem("isAuth", "true");
              this.doVideoStuff();
            }
          }
        }
      });
  }
  /**
   *
   *
   * @memberof AppComponent
   */

  toggleStatus(id) {
    switch (id) {
      case 'video1':
        this.isShow1 = !this.isShow1;
        this.isShow2 = false;
        this.isShow3 = false;
        this.isShow4 = false;
        this.isShow5 = false;
        this.isShow6 = false;
        this.isShow7 = false;
        this.isShow8 = false;
        this.isShow9 = false;
        break;
      case 'video2':
        this.isShow1 = false;
        this.isShow2 = !this.isShow2;
        this.isShow3 = false;
        this.isShow4 = false;
        this.isShow5 = false;
        this.isShow6 = false;
        this.isShow7 = false;
        this.isShow8 = false;
        this.isShow9 = false;
        break;
      case 'video3':
        this.isShow1 = false;
        this.isShow2 = false;
        this.isShow3 = !this.isShow3;
        this.isShow4 = false;
        this.isShow5 = false;
        this.isShow6 = false;
        this.isShow7 = false;
        this.isShow8 = false;
        this.isShow9 = false;
        break;
      case 'video4':
        this.isShow1 = false;
        this.isShow2 = false;
        this.isShow3 = false;
        this.isShow4 = !this.isShow4;
        this.isShow5 = false;
        this.isShow6 = false;
        this.isShow7 = false;
        this.isShow8 = false;
        this.isShow9 = false;
        break;
      case 'video5':
        this.isShow1 = false;
        this.isShow2 = false;
        this.isShow3 = false;
        this.isShow4 = false;
        this.isShow5 = !this.isShow5;
        this.isShow6 = false;
        this.isShow7 = false;
        this.isShow8 = false;
        this.isShow9 = false;
        break;
      case 'video6':
        this.isShow1 = false;
        this.isShow2 = false;
        this.isShow3 = false;
        this.isShow4 = false;
        this.isShow5 = false;
        this.isShow6 = !this.isShow6;
        this.isShow7 = false;
        this.isShow8 = false;
        this.isShow9 = false;
        break;
      case 'video7':
        this.isShow1 = false;
        this.isShow2 = false;
        this.isShow3 = false;
        this.isShow4 = false;
        this.isShow5 = false;
        this.isShow6 = false;
        this.isShow7 = !this.isShow7;
        this.isShow8 = false;
        this.isShow9 = false;
        break;
      case 'video8':
        this.isShow1 = false;
        this.isShow2 = false;
        this.isShow3 = false;
        this.isShow4 = false;
        this.isShow5 = false;
        this.isShow6 = false;
        this.isShow7 = false;
        this.isShow8 = !this.isShow8;
        this.isShow9 = false;
        break;
      case 'video9':
        this.isShow1 = false;
        this.isShow2 = false;
        this.isShow3 = false;
        this.isShow4 = false;
        this.isShow5 = false;
        this.isShow6 = false;
        this.isShow7 = false;
        this.isShow8 = false;
        this.isShow9 = !this.isShow9;
        break;
      default:
        break;
    }
    this.adminDialogVar = id;

  }

  SetTimeElspsed() {
    if (this.totaltimediferrence) {
      // console.log("Total time diferrence" +parseFloat(this.totaltimediferrence));
      // this.totaltimediferrence =parseFloat(this.totaltimediferrence) + 1 ;
      // let minutes =(this.totaltimediferrence /60);
      // console.log("Total minutes" +minutes);
      // this.totaltimeelapsed =  minutes +":" + ((this.totaltimediferrence-minutes) / 60).toFixed(0);
      let dateObj = new Date(this.totaltimediferrence * 1000);
      let hours = dateObj.getUTCHours();
      let minutes = dateObj.getUTCMinutes();
      let seconds = dateObj.getSeconds();
      let timeString = hours.toString().padStart(2, '0') + ':' +
        minutes.toString().padStart(2, '0') + ':' +
        seconds.toString().padStart(2, '0');
      this.totaltimeelapsed = timeString;
      this.totaltimediferrence = parseFloat(this.totaltimediferrence) + 1;
    }
  }
  disconnectuserWhileInternateDown() {
    if (!!localStorage.getItem("UserDataStoringLocally")) {
      var dFromLocal = <WhoDetails>JSON.parse(localStorage.getItem("UserDataStoringLocally"));
      if (dFromLocal.role.toLowerCase() == "speaker" || dFromLocal.role.toLowerCase() == "moderator") {
        this.disconnectiongSorMWhileInternatD(dFromLocal);
      }
    }
  }

  disconnectiongSorMWhileInternatD(dFromLocal: WhoDetails) {
    this.appService.forceDisconnect(dFromLocal.eventId, dFromLocal.userId).
      subscribe((data: any) => {
        window.location.reload();
      });
  }

  doingPermissionStuff() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Use your camera and microphone!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        this.requestCamera();
      } else {
      }
    });
    // navigator.getUserMedia = navigator.getUserMedia;
    // navigator.permissions.query({ name: 'camera' })
    //   .then((permissionStatus) => {
    //     console.log('geolocation permission state is ', permissionStatus.state);

    //     permissionStatus.onchange = function () {
    //       console.log('geolocation permission state has changed to ', this.state);
    //     };
    //   });
  }
  noop = () => { };
  // navigator.getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);
  requestCamera() {
    var constraints = {
      audio: true,
      video: true
    }
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      /* use the stream */
    }).catch(function (err) {
      /* handle the error */
    });
    // navigator.me.getUserMedia({ audio: true }, this.noop, this.noop)
  }

  requestMicrophone() {
    navigator.getUserMedia({ audio: true }, this.noop, this.noop)
  }


  getBrowserinfo() {
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = '' + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
      (verOffset = nAgt.lastIndexOf('/'))) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    var obj: any = {
      browserName: browserName,
      fullVersion: fullVersion,
      majorVersion: majorVersion,
      appName: navigator.appName,
      userAgent: navigator.userAgent
    };
    return JSON.stringify(obj);
  }



  ngAfterViewInit(): void {
    if (this.isShowSMModel) {
      this.doc.getElementById("openModalButton").click();
    }
    // document.getElementById('list-drp-box')
  }

  onspeack(event, data) {

    if (data == 'first') {
      if (this.allInfo == true) {
        if (this.firstSpeaker == true) {
          this.firstSpeaker = false;
        } else {
          this.firstSpeaker = true;
        }
      } else {
        if (this.firstSpeaker == true) {
          this.firstSpeaker = false;
        } else {
          this.firstSpeaker = true;
        }
      }
    } else if (data == 'second') {
      if (this.allInfo == true) {
        if (this.secondSpeaker == true) {
          this.secondSpeaker = false;
        } else {
          this.secondSpeaker = true;
        }
      } else {
        if (this.secondSpeaker == true) {
          this.secondSpeaker = false;
        } else {
          this.secondSpeaker = true;
        }
      }
    } else if (data == 'Third') {
      if (this.allInfo == true) {
        if (this.thirdSpeaker == true) {
          this.thirdSpeaker = false;
        } else {
          this.thirdSpeaker = true;
        }
      } else {
        if (this.thirdSpeaker == true) {
          this.thirdSpeaker = false;
        } else {
          this.thirdSpeaker = true;
        }
      }
    } else if (data == 'Forth') {
      if (this.allInfo == true) {
        if (this.forthSpeaker == true) {
          this.forthSpeaker = false;
        } else {
          this.forthSpeaker = true;
        }
      } else {
        if (this.forthSpeaker == true) {
          this.forthSpeaker = false;
        } else {
          this.forthSpeaker = true;
        }
      }
    } else if (data == 'Fifth') {
      if (this.allInfo == true) {
        if (this.fifthSpeaker == true) {
          this.forthSpeaker = false;
        } else {
          this.forthSpeaker = true;
        }
      } else {
        if (this.forthSpeaker == true) {
          this.forthSpeaker = false;
        } else {
          this.forthSpeaker = true;
        }
      }
    } else if (data == 'sixth') {
      if (this.allInfo == true) {
        if (this.sixthSpeaker == true) {
          this.sixthSpeaker = false;
        } else {
          this.sixthSpeaker = true;
        }
      } else {
        if (this.sixthSpeaker == true) {
          this.sixthSpeaker = false;
        } else {
          this.sixthSpeaker = true;
        }
      }
    }
    if (data == 'none') {
      this.firstSpeaker = false;
      this.secondSpeaker = false;
      this.thirdSpeaker = false;
      this.forthSpeaker = false;
      this.fifthSpeaker = false;
      this.sixthSpeaker = false;
      this.allSpeaker = false;
    }
  }

  onMouseOver(event, data) {

    if (data == 'all') {
      this.firstInfo = true;
      this.secondInfo = true;
      this.thirdInfo = true;
      this.forthInfo = true;
      this.fifthInfo = true;
      this.sixthInfo = true;
      this.seventhInfo = true;
      this.eighthInfo = true;
      this.ninthInfo = true;
      this.allInfo = true;
    } else if (data == 'first') {
      if (this.allInfo == true) {
        if (this.firstInfo == true) {
          this.firstInfo = false;
        } else {
          this.firstInfo = true;
        }
      } else {
        if (this.firstInfo == true) {
          this.firstInfo = false;
        } else {
          this.firstInfo = true;
        }
      }
    } else if (data == 'second') {
      if (this.allInfo == true) {
        if (this.secondInfo == true) {
          this.secondInfo = false;
        } else {
          this.secondInfo = true;
        }
      } else {
        if (this.secondInfo == true) {
          this.secondInfo = false;
        } else {
          this.secondInfo = true;
        }
      }
    } else if (data == 'Third') {
      if (this.allInfo == true) {
        if (this.thirdInfo == true) {
          this.thirdInfo = false;
        } else {
          this.thirdInfo = true;
        }
      } else {
        if (this.thirdInfo == true) {
          this.thirdInfo = false;
        } else {
          this.thirdInfo = true;
        }
      }
    } else if (data == 'Forth') {
      if (this.allInfo == true) {
        if (this.forthInfo == true) {
          this.forthInfo = false;
        } else {
          this.forthInfo = true;
        }
      } else {
        if (this.forthInfo == true) {
          this.forthInfo = false;
        } else {
          this.forthInfo = true;
        }
      }
    } else if (data == 'Fifth') {
      if (this.allInfo == true) {
        if (this.fifthInfo == true) {
          this.fifthInfo = false;
        } else {
          this.fifthInfo = true;
        }
      } else {
        if (this.fifthInfo == true) {
          this.fifthInfo = false;
        } else {
          this.fifthInfo = true;
        }
      }
    } else if (data == 'sixth') {
      if (this.allInfo == true) {
        if (this.sixthInfo == true) {
          this.sixthInfo = false;
        } else {
          this.sixthInfo = true;
        }
      } else {
        if (this.sixthInfo == true) {
          this.sixthInfo = false;
        } else {
          this.sixthInfo = true;
        }
      }
    } else if (data == 'seven') {
      if (this.allInfo == true) {
        if (this.seventhInfo == true) {
          this.seventhInfo = false;
        } else {
          this.seventhInfo = true;
        }
      } else {
        if (this.seventhInfo == true) {
          this.seventhInfo = false;
        } else {
          this.seventhInfo = true;
        }
      }
    } else if (data == 'eight') {
      if (this.allInfo == true) {
        if (this.eighthInfo == true) {
          this.eighthInfo = false;
        } else {
          this.eighthInfo = true;
        }
      } else {
        if (this.eighthInfo == true) {
          this.eighthInfo = false;
        } else {
          this.eighthInfo = true;
        }
      }
    } else if (data == 'nine') {
      if (this.allInfo == true) {
        if (this.ninthInfo == true) {
          this.ninthInfo = false;
        } else {
          this.ninthInfo = true;
        }
      } else {
        if (this.ninthInfo == true) {
          this.ninthInfo = false;
        } else {
          this.ninthInfo = true;
        }
      }
    }
    if (data == 'none') {
      this.firstInfo = false;
      this.secondInfo = false;
      this.thirdInfo = false;
      this.forthInfo = false;
      this.fifthInfo = false;
      this.sixthInfo = false;
      this.seventhInfo = false;
      this.eighthInfo = false;
      this.ninthInfo = false;
      this.allInfo = false;
    }

  }

  doVideoStuff = () => {
    // if (!!this.userId) {
    //   this.appService.getUserById(this.userId).
    //     subscribe(data => {
    //       this.userData = data;
    //     });
    // }

    // Get event Details
    if (!!this.eventId) {
      this.appService.getEventById(this.eventId, false).subscribe((data: any) => {
        this.event = data;
        // To display Green and Red Dot with Online and Offline
        if (!!data && !!data.broadcastId && data.broadcastId != "") {
          this.isonLine = true;
        } else {
          this.isonLine = false;
        }
        //To display Box according to find user based on sequence
        if (!!data && !!data.selectionBoxLogic && data.user) {
          data.user.forEach(a => {
            a.sqNo = data.selectionBoxLogic.find(d => { return d.selectedId == a.id }).seqno;
          });
          this.firstUser = data.user.find(a => { return a.sqNo == 1 });
          this.secondUser = data.user.find(a => { return a.sqNo == 2 });
          this.thirdUser = data.user.find(a => { return a.sqNo == 3 });
          this.forthUser = data.user.find(a => { return a.sqNo == 4 });
          this.fifthUser = data.user.find(a => { return a.sqNo == 5 });
          this.sixUser = data.user.find(a => { return a.sqNo == 6 });
          this.seventhUser = data.user.find(a => { return a.sqNo == 7 });
          this.eigththUser = data.user.find(a => { return a.sqNo == 8 });
          this.ningthUser = data.user.find(a => { return a.sqNo == 9 });
        }

        if (this.role !== undefined && this.boxid !== undefined) {
          if (this.role.toLowerCase() == "admin") {
            // this.startSession();
            this.startAdminSession();

          }
          if (this.role.toLowerCase() == "speaker" || this.role.toLowerCase() == "moderator") {
            this.getCredentials();
            // // if (this.isBroadcastStarted) {
            if (this.event.isStartByAdmin) {
              this.isShowSMModel = false;
              // this.getCredentials();
            } else {
              this.isShowSMModel = true;
              this.doc.getElementById("openModalButton").click();
            }

            // }
          }
          if (this.role.toLowerCase() == "viwer") {
            this.getCredentialsViewer();
          }
        }
      });
      this.appService.getQuestionList(this.eventId).subscribe((res: any) => {
        this.alreadyAskedQuestionList = res;
        this.alreadyAskedQuestionList.forEach(element => {
          this.questionList.push(element);
        });
      })
    } else {
      alert("Event Id not found");
    }
  }

  ngOnInit(): void {

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });


  }
  upArrow() {
    $('#textBody').scrollTop($('#textBody').scrollTop() - 50);

  }
  downArrow() {
    $('#textBody').scrollTop($('#textBody').scrollTop() + 50);

  }
  startSession() {
    // if (this.role.toLowerCase() == "admin") {
    //   this.isBroadcastStarted = true;
    //   localStorage.setItem("isBroadcastStarted", JSON.stringify(this.isBroadcastStarted));
    //   this.appService.getSession(this.eventId, 3).subscribe((response: any) => {

    //     this.sessionID = response.id

    //     this.appService.GetBroadcastStartTime(this.event.id)
    //       .subscribe((data: any) => {
    //         if (data.isBroadcastStart) {
    //           this.broadCastStartdate = new Date(data.broadcastStartOn);
    //         } else {
    //         }
    //       });

    //     this.getCredentialsHost();
    //   });
    // }
    this.startBroadcast(this.session);


  }
  redirectToDiscussion = () => {
    window.open("http://techext-001-site1.itempurl.com/home", "_self");
  }
  startAdminSession = () => {
    // to display permiddion model
    this.doingPermissionStuff();

    if (this.role.toLowerCase() == "admin") {
      // this.isBroadcastStarted = true;
      // localStorage.setItem("isBroadcastStarted", JSON.stringify(this.isBroadcastStarted));

      // To get session id and
      this.appService.getSession(this.eventId, 3).subscribe((response: any) => {

        this.sessionID = response.id

        // this.appService.GetBroadcastStartTime(this.event.id)
        //   .subscribe((data: any) => {
        //     if (data.isBroadcastStart) {
        //       this.broadCastStartdate = new Date(data.broadcastStartOn);
        //     } else {
        //     }
        //   });

        this.getCredentialsHost();
      });
    }
  }



  stopSession() {
    this.endBroadcast();
  }

  updateBroadCastVar() {
    this.isBroadcastStarted = true;
  }

  checkUser() {
    // stop here if form is invalid
    if (this.emailAddress == "" || this.accessCode == "") {
      alert("Email Address and Accesscode are required.");
    } else {
      let data = new UserFilterModel();
      data.eventId = this.eventId;
      data.userId = this.userId;
      data.emailAddress = this.emailAddress;
      data.accessCode = this.accessCode;

      this.appService.checkUser(data)
        .subscribe((data: boolean) => {
          if (data) {

            this.isUserAuthenticated = true;
            localStorage.setItem("isAuth", "true");
            this.doVideoStuff();
          } else {
            alert("User is not available.");
          }
        });
    }

    // this.authenticationService.login(this.f.username.value, this.f.password.value)
    //     .pipe(first())
    //     .subscribe(
    //         data => {
    //             this.router.navigate([this.returnUrl]);
    //         },
    //         error => {
    //             this.alertService.error(error);
    //             this.loading = false;
    //         });
  }

  //GUEST//
  insertOptions = {
    width: '100%',
    height: '100%',
    showControls: true,
    buttonDisplayMode: true,
    audioVolume: this.val,
    style: { nameDisplayMode: "off", buttonDisplayMode: 'off' }
  };

  getCredentials = () => {
    this.doingPermissionStuff();
    this.appService.getCredential(this.eventId, 1).subscribe((response: any) => {
      let credentials = {
        apiKey: response.apiKey,
        sessionId: response.sessionId,
        token: response.token
      };
      // To start discussion for Speaker and moderator
      this.init(credentials);
    })
  };

  initPublisher = () => {
    let insertOptions = {
      width: '100%',
      height: '100%',
      showControls: true,
      buttonDisplayMode: true,
      style: { nameDisplayMode: "off", buttonDisplayMode: 'off' },
      resolution: '320x240',
    };
    // const canvasStream = getCanvasStream();
    let element = 'video' + this.boxid;
    this.IDD = element
    var properties: any = Object.assign({ name: element, insertMode: 'append' }, insertOptions);
    return OT.initPublisher(element, properties);

  };

  handleChange(e) {
    this.subscriptionData.setAudioVolume(e.value);
  }

  subscribe = (session, stream) => {
    var properties = Object.assign({ name: stream.name, insertMode: 'append' }, this.insertOptions);
    this.subscriptionData = session.subscribe(stream, stream.name, properties, (error) => {
      if (error) {
      }
      this.val = this.subscriptionData.getAudioVolume();
    });
    this.whoIsSpeakingIdentify();
  };

  // To place Video and Audio Button , Mike , Sound
  addPublisherControls = (publisher) => {
    var publisherContainer = document.getElementById(publisher.element.id);
    var el = document.createElement('div');
    var controls = [
      '<div class="publisher-controls-container">',
      '<div id="publishVideo" class="control video-control"></div>',
      '<div id="publishAudio" class="control audio-control"></div>',
      '</div>',
    ].join('\n');
    el.innerHTML = controls;
    publisherContainer.appendChild(el.firstChild);
  };

  //
  publishAndSubscribe = (session, publisher) => {
    this.receivedQuestion = [];

    var streams = 1;
    session.publish(publisher);
    this.addPublisherControls(publisher);

    session.on('streamCreated', (event) => {
      this.subscribe(session, event.stream);
      streams++;
    });

    session.on('streamDestroyed', (event) => {
      console.log(event.reason);
      this.subscribe(session, event.stream);
      streams--;

      if (this.role == "speaker" || this.role == "moderator") {

        if (!!event && !!event.stream && !!event.stream.connection && !!event.stream.connection.connectionId) {
          if (this.session.connection.connectionId == event.stream.connection.connectionId) {
            // If speaker / Moderator Leave from discussuion
            this.openSpeakerPopup();
          }
        }

      }
    });


    // Moderator Or Speaker Connected...
    session.on('connectionCreated', (event) => {

      //  console.log("connectionCreated")
      this.appService.GetBroadcastStartTime(this.event.id)
        .subscribe((data: any) => {
          //    console.log("Get Broadcast time from connectionCareated"  +data.broadCastStartdate+"Broadcast started On" + data.broadcastStartOn)
          if (data.isBroadcastStart) {
            this.broadCastStartdate = new Date(data.broadcastStartOn);
            this.totaltimediferrence = data.timeelapsed;
          } else {

          }
        });

    });

    session.on('connectionDestroyed', (event) => {
      this.myDate = undefined;
      this.broadCastStartdate = undefined;
    });


    session.on('signal:question', (event: { data: string; }) => {
      if (this.questionList == undefined) {
        this.questionList = []
      }
      this.questionList.push(event.data);
      this.alreadyAskedQuestionList.push(event.data);
    });

    session.on('signal:startedBroadcast', (event) => {
      this.isonLine = true;
      this.appService.GetBroadcastStartTime(this.event.id)
        .subscribe((data: any) => {
          if (data.isBroadcastStart) {
            this.broadCastStartdate = new Date(data.broadcastStartOn);
            this.totaltimediferrence = data.timeelapsed;
          }
        });
    });


    session.on('signal:endedBroadcast', (event) => {
      console.log("Session Destroyed")
      this.broadCastStartdate = undefined;
      this.isonLine = false;
      console.log("Session Destroyed" + this.broadCastStartdate)

    });



    session.on('signal:sessionStoped', (event) => {
      if (this.role === "speaker" || this.role == "moderator") {
        // IF session END
        this.openSpeakerPopup();
      }
    });

    session.on('signal:sessionStopedRemove', (event) => {
      this.route.queryParams.subscribe(res => {
        this.userIDFromRoute = res.userId;
      })
      if (this.role === "speaker" || this.role == "moderator") {
        if (event.data == this.userIDFromRoute) {
          // IF session END
          this.openSpeakerPopup();
        }
      }
    });

    session.on('signal:muteSpeaker', (event) => {
      this.route.queryParams.subscribe(res => {
        this.userIDFromRoute = res.userId;
      })
      if (this.role === "speaker" || this.role == "moderator") {
        if (event.data.userid == this.userIDFromRoute) {
          this.audiooff(event.data.userid, event.data.boxid);
        }
      }
    });

    session.on('signal:join', (event) => {
      this.totalViwer = event.data - streams;
      this.count = this.totalViwer;
    });
  };

  openSpeakerPopup() {
    //
    this.doc.getElementById("openSpeakerModel").click();
  }

  openSessionPopup() {
    this.doc.getElementById("openSessionCompleteModel").click();
  }
  // To identify who is speaking at the moemnt
  private whoIsSpeakingIdentify() {
    // not working at the moment
    this.subscriptionData.on('audioLevelUpdated', (event) => {
      if (event.target.stream.name !== undefined) {
        let id = event.target.stream.name[event.target.stream.name.length - 1];
        if (id !== undefined) {
          let record = _.find(this.event.selectionBoxLogic, d => d.seqno == id);
          if (record !== undefined) {
            let user = _.find(this.event.user, u => u.id == record.selectedId);

            this.whoIsSpeaking = {
              name: user.personName,
              company: user.company,
              logo: user.logo,
              domId: event.target.stream.name
            };

            this.recordId = '';
            if (id == "1") {
              this.recordId = 'first';
            } else if (id == "2") {
              this.recordId = 'second';
            } else if (id == "3") {
              this.recordId = 'Third';
            } else if (id == "4") {
              this.recordId = 'Forth';
            } else if (id == "5") {
              this.recordId = 'Fifth';
            } else if (id == "6") {
              this.recordId = 'sixth';
            }
            this.onspeack('data', this.recordId);
          }
        }
      }
    });
  }

  init(credentials) {
    this.session = OT.initSession(credentials.apiKey, credentials.sessionId);
    this.publisher = this.initPublisher();

    this.session.connect(credentials.token, (error) => {
      if (error) {
      } else {
        this.CONNECT = this.session.connection.connectionId;
        let data = {
          eventId: this.eventId,
          userId: this.userId,
          connectionId: this.CONNECT
        }
        // To set connection ID in EventUserMapping table in databse
        this.appService.setConnenctionSession(data).subscribe(data => {
        })
        this.publishAndSubscribe(this.session, this.publisher);
      }
    });
  };

  // not in use
  toggleMedia = (publisher, el) => {
    var enabled = el.classList.contains('disabled');
    el.classList.toggle('disabled');
    publisher[el.id](enabled);
  };

  //VIWER//
  ///////////////////////////////////////////////////////////////////////////////////
  insertViewerOptions = {
    width: '100%',
    height: '100%',
    showControls: false,
    style: { nameDisplayMode: "off" }
  };

  getCredentialsViewer = () => {
    this.appService.getCredential(this.eventId, 2)
      .subscribe((response: any) => {
        let credentials = {
          apiKey: response.apiKey,
          sessionId: response.sessionId,
          token: response.token
        };
        // to startb discussuion for viewer
        this.initViwer(credentials);
      })
  };

  subscribeViwer = (session, stream) => {
    var name = stream.name;
    let inserViwerOptions = {
      width: '100%',
      height: '100%',
      style: { nameDisplayMode: "off" }
    }
    var properties = Object.assign({ name: stream.name, insertMode: 'append' }, inserViwerOptions);
    this.subscriptionData = session.subscribe(stream, stream.name, properties, (error) => {
      if (error) {
      }
    });
    this.val = this.subscriptionData.getAudioVolume();
    this.whoIsSpeakingIdentify();
    return this.subscriptionData
  };

  checkBroadcastStatus = (session, isOnline?: any) => {
    session.signal({
      type: 'broadcast',
      data: isOnline
    });
  };
  /**
   *Event listener
   *
   * @memberof AppComponent
   */
  setEventListeners = (session) => {
    var streams = [];
    var subscribers = [];
    var broadcastActive = false;

    session.on('streamCreated', (event) => {
      streams.push(event.stream);
      if (broadcastActive) {
        subscribers.push(this.subscribeViwer(session, event.stream));
      }
    });

    session.on('streamDestroyed', (event) => {

      var index = streams.indexOf(event.stream);
      streams.splice(index, 1);
      console.log("stream destroyed")
      this.myDate = undefined;
      this.broadCastStartdate = undefined;
      if (!!this.audianceId) {
        this.saveAudianceDetailAPI();
        let url = "http://techext-001-site1.itempurl.com/feedback/?auId=" + this.audianceId + "&eventId=" + this.eventId;
        window.open(url, "_self");
      }
    });

    // Viwer connected....
    session.on('connectionCreated', (event) => {
      this.totalViwer = this.totalViwer + 1;
      let signalData = Object.assign({}, { type: 'join', data: this.totalViwer });
      countSignal(session, signalData);
      this.audianceStartTime = new Date();

      this.appService.GetBroadcastStartTime(this.event.id)
        .subscribe((data: any) => {
          if (data.isBroadcastStart) {
            this.broadCastStartdate = new Date(data.broadcastStartOn);
            this.totaltimediferrence = data.timeelapsed;
          } else {
          }
        });
    });

    session.on('connectionDestroyed', (event) => {
      if (this.totalViwer > 0) {
        this.totalViwer = this.totalViwer - 1;
      } else {
        this.totalViwer = 0;
      }
      let signalData = Object.assign({}, { type: 'join', data: this.totalViwer });

      countSignal(session, signalData);

    });

    session.on('signal:broadcast', (event: { data: any; }) => {
      var onlineOrNot = event.data;
      // if(event.data=='status'){
      //   broadcastActive = status;
      //   status ='active';
      // }
      if (onlineOrNot) {
        broadcastActive = true;
        var status = 'active';
      }
      // broadcastActive = status === 'active';

      if (status === 'active') {
        streams.forEach((stream) => {
          subscribers.push(this.subscribeViwer(session, stream));
        });
      } else if (status === 'ended') {
        subscribers.forEach((subscriber) => {
          session.unsubscribe(subscriber);
        });
        if (!!this.audianceId) {
          this.saveAudianceDetailAPI();
        }

      }
    });
  };
  /**
   *Init viewer
   *
   * @memberof AppComponent
   */
  initViwer = (credentials) => {
    this.session = OT.initSession(credentials.apiKey, credentials.sessionId);
    this.session.connect(credentials.token, (error) => {

      if (error) {
      } else {
        this.setEventListeners(this.session);
        this.checkBroadcastStatus(this.session, this.isonLine);
      }
    });
  };

  //HOST//
  /////////////////////////////////////////////////////////////////
  /** The state of things */
  broadcast: any = { status: 'waiting', streams: 1, rtmp: false };

  getCredentialsHost = () => {
    this.appService.getCredential(this.eventId, 3).subscribe((response: any) => {
      let credentials = {
        apiKey: response.apiKey,
        sessionId: response.sessionId,
        token: response.token
      };
      this.initHost(credentials)
    })
  };
  /**
   *Init publisher user
   *
   * @memberof AppComponent
   */
  initPublisherHost = () => {
    let insertOptionsHost = {
      width: '0%',
      height: '0%',
      showControls: false,
      buttonDisplayMode: false,
      publishAudio: false,
      publishVideo: false,
      style: { nameDisplayMode: "off", buttonDisplayMode: 'off' },
      resolution: '320x240',
    };
    let element = "admin";
    this.IDD = element
    var properties: any = Object.assign({ name: element, insertMode: 'append' }, insertOptionsHost);
    return OT.initPublisher(element, properties);
  }

  signal = (session, status, to?) => {
    var signalData = Object.assign({}, { type: 'broadcast', data: status }, to ? { to } : {});
    session.signal(signalData, (error) => {
      if (error) {
        //   console.log(['signal error (', error.code, '): ', error.message].join(''));
      } else {
        console.log('signal sent');
      }
    });
  };

  getBroadcastUrl = (params) => {
    var buildQueryString = (query, key) => {
      return [query, key, '=', params[key], '&'].join('');
    };
    var queryString = R.reduce(buildQueryString, '?', R.keys(params)).slice(0, -1);
    return [this.doc.location.host, '/broadcast', queryString].join('');
  };

  updateStatus = (session, status) => {
    var playerUrl = this.getBroadcastUrl(this.broadcast.hls);
    var displayUrl = document.getElementById('broadcastURL');
    // var rtmpActive = document.getElementById('rtmpActive');
    this.broadcast.status = status;
    if (status === 'active') {
      // startStopButton.classList.add('active');
      // startStopButton.innerHTML = 'End Broadcast';
      // document.getElementById('urlContainer').classList.remove('hidden');
      displayUrl.innerHTML = playerUrl;
      displayUrl.setAttribute('value', playerUrl);
      if (this.broadcast.rtmp) {
        // rtmpActive.classList.remove('hidden');
      }
    } else {
      // startStopButton.classList.remove('active');
      // startStopButton.innerHTML = 'Broadcast Over';
      // startStopButton.disabled = true;
      // rtmpActive.classList.add('hidden');
    }
    this.signal(session, this.broadcast.status);
  };

  showCopiedNotice = () => {
    var notice = document.getElementById('copyNotice');
    notice.classList.remove('opacity-0');
    setTimeout(() => {
      notice.classList.add('opacity-0');
    }, 1500);
  };

  validRtmp = () => {
    var server: any = document.getElementById('rtmpServer');
    var stream: any = document.getElementById('rtmpStream');
    var serverDefined = !!server.value;
    var streamDefined = !!stream.value;
    var invalidServerMessage = 'The RTMP server url is invalid. Please update the value and try again.';
    var invalidStreamMessage = 'The RTMP stream name must be defined. Please update the value and try again.';

    if (serverDefined && !server.checkValidity()) {
      document.getElementById('rtmpLabel').classList.add('hidden');
      document.getElementById('rtmpError').innerHTML = invalidServerMessage;
      document.getElementById('rtmpError').classList.remove('hidden');
      return null;
    }

    if (serverDefined && !streamDefined) {
      document.getElementById('rtmpLabel').classList.add('hidden');
      document.getElementById('rtmpError').innerHTML = invalidStreamMessage;
      document.getElementById('rtmpError').classList.remove('hidden');
      return null;
    }

    document.getElementById('rtmpLabel').classList.remove('hidden');
    document.getElementById('rtmpError').classList.add('hidden');
    return { serverUrl: server.value, streamName: stream.value };
  };

  hideRtmpInput = () => {
    ['rtmpLabel', 'rtmpError', 'rtmpServer', 'rtmpStream'].forEach((id) => {
      document.getElementById(id).classList.add('hidden');
    });
  };
  /**
   *Start Broadcast
   *
   * @memberof AppComponent
   */
  startBroadcast = (session) => {
    console.log("Broadcast Started")
    this.isBroadcastStarted = true;
    // localStorage.setItem("isBroadcastStarted", JSON.stringify(this.isBroadcastStarted));
    this.appService.getBroadCast(session.sessionId, this.event.id).subscribe(data => {
      this.appService.GetBroadcastStartTime(this.event.id)
        .subscribe((datas: any) => {
          if (datas.isBroadcastStart) {
            console.log("Broadcast Started time" + datas.broadcastStartOn);
            this.broadCastStartdate = new Date(datas.broadcastStartOn);
            this.totaltimediferrence = datas.timeelapsed;
            this.isonLine = true;
            this.broadCastStartSignalForSM();
            this.checkBroadcastStatus(this.session, this.isonLine);
            const broadcastData = {
              id: R.path(['id'], data),
              session: session.sessionId,
              rtmp: !!R.path(['broadcastUrls', 'rtmp'], data),
              url: R.path(['broadcastUrls', 'hls'], data),
              apiKey: R.path(['partnerId'], data),
              availableAt: R.path(['createdAt'], data)
            };
            this.broadcast = _.merge(this.broadcast, broadcastData);
            this.updateStatus(session, 'active');
          }
        });
    })
  };

  broadCastStartSignalForSM() {
    this.session.signal(
      {
        data: "Broadcast Started",
        type: "startedBroadcast"
      },
      (error) => {
        if (error) {
          console.log("signal error ("
            + error.name
            + "): " + error.message);
        } else {
          console.log("Broadcast Started signal sent.");

        }
      }
    );
  }

  broadCastEndSignalForSM() {
    this.session.signal(
      {
        data: "Broadcast Ended",
        type: "endedBroadcast"
      },
      (error) => {
        if (error) {
          console.log("signal error ("
            + error.name
            + "): " + error.message);
        } else {
          console.log("Broadcast Ended signal sent.");

        }
      }
    );
  }


  //Disconnecting all the viwers
  endBroadcast = () => {
    this.appService.stopBroadcast(this.event.id).subscribe(data => {
      if (data) {
        this.isBroadcastStarted = false;
        this.isonLine = false;
        // localStorage.setItem("isBroadcastStarted", JSON.stringify(this.isBroadcastStarted));
        this.session.signal(
          {
            data: "Broadcast Ended",
            type: "endedBroadcast"
          },
          (error) => {
            if (error) {
              console.log("signal error ("
                + error.name
                + "): " + error.message);
            } else {
              console.log("Broadcast Ended signal sent.");

            }
          }
        );

        this.signal(this.session, 'ended');
      }
    });
  };



  subscribeHost = (session, stream) => {
    let insertOptionsHost = {
      width: '100%',
      height: '100%',
      showControls: true,
      buttonDisplayMode: true,
      style: { nameDisplayMode: "off", buttonDisplayMode: 'off' }
    }
    var properties = Object.assign({ name: stream.name, insertMode: 'append' }, insertOptionsHost);
    this.subscriptionData = session.subscribe(stream, stream.name, properties, (error) => {
      if (error) {
        console.log(error);
      }
    });
    this.val = this.subscriptionData.getAudioVolume();
    this.whoIsSpeakingIdentify();
  };

  toggleMediaHost = (publisher, el) => {
    var enabled = el.classList.contains('disabled');
    el.classList.toggle('disabled');
    publisher[el.id](enabled);
  };

  updateBroadcastLayout = () => {
    // http.post('/broadcast/layout', { streams: this.broadcast.streams })
    //   .then(function (result) { console.log(result); })
    //   .catch(function (error) { console.log(error); });
  };
  /**
   *Used to handle opentok event here
   *
   * @memberof AppComponent
   */
  setEventListenersHost = (session, publisher) => {
    // Add click handler to the start/stop button
    // var startStopButton = document.getElementById('startStop');
    // startStopButton.classList.remove('hidden');
    // startStopButton.addEventListener('click', () => {
    //   if (this.broadcast.status === 'waiting') {
    //     this.startBroadcast(session);
    //   } else if (this.broadcast.status === 'active') {
    //     this.endBroadcast(session);
    //   }
    // });

    // Subscribe to new streams as they're published
    session.on('streamCreated', (event) => {
      var currentStreams = this.broadcast.streams;
      this.subscribeHost(session, event.stream);
      this.broadcast.streams++;
      if (this.broadcast.streams > 3) {
        // document.getElementById('videoContainer').classList.add('wrap');
        if (this.broadcast.status === 'active' && currentStreams <= 3) {
          this.updateBroadcastLayout();
        }
      }
    });

    session.on('streamDestroyed', (event) => {

      var currentStreams = this.broadcast.streams;
      this.broadcast.streams--;
      if (this.broadcast.streams < 4) {

        if (this.broadcast.status === 'active' && currentStreams >= 4) {
          this.updateBroadcastLayout();
        }
      }
    });

    // Signal the status of the broadcast when requested
    session.on('signal:broadcast', (event) => {

      if (event.data === 'status') {
        this.signal(session, this.broadcast.status, event.from);
      }
    });

    //Admin Connected...
    session.on('connectionCreated', (event) => {

      this.appService.GetBroadcastStartTime(this.event.id)
        .subscribe((data: any) => {
          if (data.isBroadcastStart) {
            this.broadCastStartdate = new Date(data.broadcastStartOn);
            this.totaltimediferrence = data.timeelapsed;
          } else {
          }
        });

    });



    session.on('signal:question', (event: { data: string; }) => {
      if (this.questionList == undefined) {
        this.questionList = []
      }
      this.questionList.push(event.data);
      this.alreadyAskedQuestionList.push(event.data);
    });



    session.on('signal:join', (event) => {
      this.totalViwer = event.data - this.broadcast.streams;
      this.count = this.totalViwer;
    });

    session.on('signal:muteSpeakerAdmin', (event) => {
      console.log("admin===========", event['data']);
      // if (event['data'].getaudiostatus == false) {
      //   this.isaudioOnOrOff = true;
      // } else {
      //   this.isaudioOnOrOff = false;
      // }
      if (event['data'].getaudiostatus == false) {
        switch (event['data'].boxid) {
          case 'video1':
            this.isaudioOnOrOff1 = true;
            break;
          case 'video2':
            this.isaudioOnOrOff2 = true;
            break;
          case 'video3':
            this.isaudioOnOrOff3 = true;
            break;
          case 'video4':
            this.isaudioOnOrOff4 = true;
            break;
          case 'video5':
            this.isShow5 = !this.isShow5;
            break;
          case 'video6':
            this.isShow6 = !this.isShow6;
            break;
          case 'video7':
            this.isShow7 = !this.isShow7;
            break;
          case 'video8':
            this.isShow8 = !this.isShow8;
            break;
          case 'video9':
            this.isShow9 = !this.isShow9;
            break;
          default:
            break;
        }
      } else {
        switch (event['data'].boxid) {
          case 'video1':
            this.isaudioOnOrOff1 = false;
            break;
          case 'video2':
            this.isaudioOnOrOff2 = false;
            break;
          case 'video3':
            this.isaudioOnOrOff3 = false;
            break;
          case 'video4':
            this.isaudioOnOrOff4 = false;
            break;
          case 'video5':
            this.isShow5 = !this.isShow5;
            break;
          case 'video6':
            this.isShow6 = !this.isShow6;
            break;
          case 'video7':
            this.isShow7 = !this.isShow7;
            break;
          case 'video8':
            this.isShow8 = !this.isShow8;
            break;
          case 'video9':
            this.isShow9 = !this.isShow9;
            break;
          default:
            break;
        }
      }
    });
  };

  addPublisherControlsHost = (publisher) => {
    var publisherContainer = document.getElementById(publisher.element.id);
    var el = document.createElement('div');
    var controls = [
      '<div class="publisher-controls-container">',
      '<div id="publishVideo" class="control video-control"></div>',
      '<div id="publishAudio" class="control audio-control"></div>',
      '</div>',
    ].join('\n');
    el.innerHTML = controls;
    publisherContainer.appendChild(el.firstChild);
  };

  publishAndSubscribeHost = (session, publisher) => {
    session.publish(publisher);
    this.addPublisherControls(publisher);
    this.setEventListenersHost(session, publisher);
    // this.startBroadcast(session);
  };


  /**
   *Init host used to start open tok
   *
   * @memberof AppComponent
   */
  initHost = (credentials) => {
    this.session = OT.initSession(credentials.apiKey, credentials.sessionId);
    var publisher = this.initPublisherHost();

    let sessionConnect = this.session.connect(credentials.token, (error) => {
      this.CONNECT = this.session.connection.connectionId;
      if (error) {
        console.log(error);
      } else {
        this.publishAndSubscribeHost(this.session, publisher);
      }
    });
  };

  removeUserFromEvent() {
    // to make user out of session
    let id = this.adminDialogVar;
    let iid = id[id.length - 1];
    if (iid !== undefined) {
      let record = _.find(this.event.selectionBoxLogic, d => d.seqno == iid);
      this.sendSignalToSpeakerWithUserID(record.selectedId);
      if (record !== undefined) {
        let user = _.find(this.event.user, u => u.id == record.selectedId);
        this.appService.forceDisconnect(this.eventId, user.id).subscribe(data => {
        });
        this.toggleStatus(id);
      }
      this.isSpeakerRemoved = true;
    }
    setTimeout(() => {
      this.isSpeakerRemoved = false;
      $('#btn-close').trigger('click');
    },3000)
  }

  cancelFromRemove() {
    let id = this.adminDialogVar;
    let iid = id[id.length - 1];
    if (iid !== undefined) {
      this.toggleStatus(id);
    }
  }

  muteSpeakerByAdmin() {
    let id = this.adminDialogVar;
    let iid = id[id.length - 1];
    if (iid !== undefined) {
      let record = _.find(this.event.selectionBoxLogic, d => d.seqno == iid);
      this.sendSignalToSpeakerForMute(record.selectedId, id);
      this.toggleStatus(id);
    }
  }

  leave() {


    // if (this.role == 'admin') {
    //   // Remove session and broadcast id from event
    //   // and forcedisconnect all users

    //   this.appService.stopBroadcast(this.event.id).subscribe(data => {
    //     this.isBroadcastStarted = false;
    //     localStorage.setItem("isBroadcastStarted", JSON.stringify(this.isBroadcastStarted));

    //     this.signal(this.session, 'ended');

    //     this.appService.stopdiscussion(this.eventId)
    //       .subscribe((data: boolean) => {
    //         if (data) {
    //           // this.disConnectAllUsers();
    //           this.isBroadcastStarted = false;
    //           this.broadCastStartdate = undefined;
    //           this.myDate = undefined;
    //           this.session.disconnect();

    //         } else {
    //           alert("Error while Closing Event.");
    //         }
    //       });
    //   });

    // } else {

    //   this.session.disconnect();
    //   // if (this.role == "viwer") {
    //   //   let url = "http://discussionbox.com/feedback/?auId=" + this.audianceId + "&eventId=" + this.eventId;
    //   //   window.open(url, "_self");
    //   // }
    // }

    if (this.role == 'admin') {
      // Remove session and broadcast id from event
      // and forcedisconnect all users

      this.sendSignalToSpeaker();
      this.appService.stopdiscussion(this.eventId)
        .subscribe((data: boolean) => {
          if (data) {

            // this.disConnectAllUsers();
            this.isBroadcastStarted = false;
            this.broadCastStartdate = undefined;
            this.myDate = undefined;
            this.signal(this.session, 'ended');
            this.session.disconnect();
            this.openSessionPopup();
            console.log("broad cast stpeed" + this.broadCastStartdate + this.myDate)
          } else {
            alert("Error while Closing Event.");
          }
        });
    } else {
      // this.session.disconnect();
      if (this.role == "viwer") {
        // this.sendSignalToSpeaker();
        // this.openSessionPopup();
        // if (!!this.audianceId) {
        //   this.saveAudianceDetailAPI();
        // }
        this.session.disconnect();
        let url = "http://techext-001-site1.itempurl.com//feedback/?auId=" + this.audianceId + "&eventId=" + this.eventId;
        window.open(url, "_self");
      }
      else if (this.role === "speaker" || this.role === "moderator") {
        this.doc.getElementById("openSpeakerModel").click();
      }
    }

  }

  sendSignalToSpeaker() {
    this.session.signal(
      {
        // to: connection1,
        data: "sessionStop",
        type: "sessionStoped"
      },
      (error) => {
        if (error) {
          console.log("signal error ("
            + error.name
            + "): " + error.message);
        } else {
          console.log("signal sent.");
          this.question = ""
        }
      }
    );
  }

  sendSignalToSpeakerWithUserID(userID) {
    this.session.signal(
      {
        data: userID,
        type: "sessionStopedRemove"
      },
      (error) => {
        if (error) {
          console.log("signal error ("
            + error.name
            + "): " + error.message);
        } else {
          console.log("signal sent.");
          this.question = ""
        }
      }
    );
  }

  sendSignalToSpeakerForMute(userID, id) {
    let alldata: any = {
      userid: userID,
      boxid: id
    }
    this.session.signal(
      {
        data: alldata,
        type: "muteSpeaker"
      },
      (error) => {
        if (error) {
          console.log("signal error ("
            + error.name
            + "): " + error.message);
        } else {
          console.log("signal sent.");
          this.question = ""
        }
      }
    );
  }
  sendSignalToAdminForMute(getaudiostatus, boxid) {
    let allDataForAdmin: any = {
      getaudiostatus: getaudiostatus,
      boxid: boxid
    }
    this.session.signal(
      {
        data: allDataForAdmin,
        type: "muteSpeakerAdmin"
      },
      (error) => {
        if (error) {
          console.log("signal error ("
            + error.name
            + "): " + error.message);
        } else {
          console.log("signal sent.");
          this.question = ""
        }
      }
    );
  }

  saveAudianceDetailAPI = () => {
    let dataToSave = new AudianceDataToSaveModel();
    dataToSave = {
      audianceId: this.audianceId,
      audianceStartTime: this.audianceStartTime,
      browserTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      eventId: this.eventId,
      questionList: !!this.questionList ? JSON.stringify(this.questionList) : ""
    };

    this.appService.AddEndTimeWhileVideoRunning(dataToSave)
      .subscribe(data => {
        if (data) {
          let url = "http://techext-001-site1.itempurl.com/feedback/?auId=" + this.audianceId + "&eventId=" + this.eventId;
          window.open(url, "_self");
        }
      });


  }

  adminDialog(id) {
    this.adminDialogVar = id;
  }

  addQuestion = () => {
    let dataToPush: any = {};
    if (this.question.length > 0) {
      this.IsQueNull = true;
      if (!!this.audianceDetail) {
        dataToPush.audianceName = !!this.audianceDetail.fullName ? this.audianceDetail.fullName : "";
        dataToPush.jobTitle = !!this.audianceDetail.jobTitle ? this.audianceDetail.jobTitle : "";
        dataToPush.companyName = !!this.audianceDetail.companyName ? this.audianceDetail.companyName : "";
      }

      dataToPush.question = this.question;
      this.questionList.push(dataToPush);
      this.session.signal(
        {
          // to: connection1,
          data: dataToPush,
          type: "question"
        },
        (error) => {
          if (error) {
            console.log("signal error ("
              + error.name
              + "): " + error.message);
          } else {
            this.submitted = !this.submitted
            console.log("signal sent.");
            let questionModel = new AudianceQuestionModel();
            questionModel.audianceId = this.audianceId;
            questionModel.eventId = this.eventId;
            questionModel.audianceName = this.audianceDetail.fullName;
            questionModel.question = this.question;
            this.alreadyAskedQuestionList.push(dataToPush)
            this.appService.AddQuestionsWhileVideoRunning(questionModel)
              .subscribe((data: any) => {
                this.question = "";
              });
            setTimeout(() => {
              this.submitted = !this.submitted;
              $('#btn-close').trigger('click');
            }, 3000);
          }
        }
      );

    } else {
      this.IsQueNull = false;
    }

  }
  videooff() {
    this.isvideoOff = !this.isvideoOff
    this.publisher.publishVideo(this.isvideoOff)
  }

  audiooff(userid?: any, boxId?: any) {
    if (!boxId) {
      this.route.queryParams.subscribe(res => {
        this.userIDFromRoute = res.userId;
      })
      let user = _.find(this.event.user, u => u.id == this.userIDFromRoute);
      boxId = 'video' + user.sqNo;
    }
    this.isaudioOff = !this.isaudioOff
    this.publisher.publishAudio(this.isaudioOff)
    this.sendSignalToAdminForMute(this.isaudioOff, boxId);
  }

  /**
   *Used to disconnect all user
   *
   * @memberof AppComponent
   */
  disConnectAllUsers = () => {
    _.each(this.event.user, a => {
      this.appService.forceDisconnect(this.eventId, a.id).subscribe(data => {
      })
    })
  }

  closeDiscussion = () => {

  }

  gotoHome = () => {
    window.open("http://techext-001-site1.itempurl.com/", "_self");
  }
}
function countSignal(session: any, signalData: { type: string; data: number; }) {
  session.signal(signalData, (error) => {
    if (error) {
      console.log(['signal error (', error.code, '): ', error.message].join(''));
    }
    else {
      console.log('signal sent');
    }
  });
}


const getCanvasStream = () => {
  let videoElement;
  let filterVideo;
  let ctx;
  let stopped = false;
  let filterCtx;
  let filterCanvas;
  let cameraCtx;
  let cameraCanvas;

  const drawFrame = () => {
    //ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
    const cameraData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    if (!stopped) {
      requestAnimationFrame(drawFrame);
    } else {
      ctx = null;
    }
  };



  // Get the Camera video
  OT.getUserMedia({
    audioSource: null
  }).then((stream) => {
    videoElement = document.getElementById('videonew');
    videoElement.srcObject = stream;
    videoElement.setAttribute('loop', true)
    videoElement.play();
    //cameraCanvas = document.createElement('canvas');
    // canvas.width = videoElement.width = 640;
    // canvas.height = videoElement.height = 480;

    //requestAnimationFrame(drawFrame);
  });

  return {
    canvas,
    stop: () => {
      stopped = true;
    }
  };


};
